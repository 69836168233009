<template>
  <v-container>
    <app-loading v-if="loading"/>
    <v-card v-else-if="shopsNoConfig.length > 0 || managerConfig">
      <v-card-title>
      <span class="headline">
        {{ $vuetify.lang.t("$vuetify.menu.shop_online") }}
      </span>
      </v-card-title>

      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            :editable="formValid"
            step="1"
          >
            {{ $vuetify.lang.t("$vuetify.panel.basic") }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :step="2"
            editable
            :complete="step > 2"
          >
            {{
              $vuetify.lang.t(
                "$vuetify.representation.representation"
              )
            }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :step="3"
            editable
          >
            {{
              $vuetify.lang.t(
                "$vuetify.menu.pasarela_config"
              )
            }}
          </v-stepper-step>
          <v-divider/>
          <v-stepper-step
            :step="4"
            editable
          >
            {{
              $vuetify.lang.t(
                "$vuetify.menu.manager_article"
              )
            }}
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-content step="1">
          <v-card>
          <basic :managerConfig="managerConfig" :shopsNoConfig="shopsNoConfig" :isShopLoading="isShopLoading"/>
          <v-card-actions>
            <v-btn
              class="mb-2"
              :disabled="!formValid || isShopLoading"
              @click="step = 2"
            >
              {{
                smallScreen ? '' : $vuetify.lang.t("$vuetify.actions.next")
              }}
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
            <v-spacer/>

            <v-btn
              class="mb-2"
              color="error"
              :disabled="isShopLoading"
              @click="
                    $router.push({ name: 'shop_online' })
                  "
            >
              <v-icon>mdi-close</v-icon>
              {{
                smallScreen ? '' :
                  $vuetify.lang.t(
                    "$vuetify.actions.cancel"
                  )
              }}
            </v-btn>
            <v-btn
              class="mb-2"
              color="primary"
              :disabled="!formValid || isShopLoading"
              :loading="isShopLoading"
              @click="managerConfigHandler"
            >
              <v-icon>mdi-content-save</v-icon>
              {{
                smallScreen ? '' :
                  $vuetify.lang.t("$vuetify.actions.save")
              }}
            </v-btn>
          </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content :step="2">
          <v-card>
            <v-card-text>
              <representation :config="config" :manager-config="managerConfig"/>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="mb-2"
                @click="step = 1"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.back")
                }}
              </v-btn>
              <v-btn
                class="mb-2"
                @click="step = 3"
              >
                <v-icon>mdi-chevron-right</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.next")
                }}
              </v-btn>
              <v-spacer/>
              <v-btn
                class="mb-2"
                color="error"
                :disabled="isShopLoading"
                @click="
                    $router.push({ name: 'shop_online' })
                  "
              >
                <v-icon>mdi-close</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t(
                      "$vuetify.actions.cancel"
                    )
                }}
              </v-btn>
              <v-btn
                class="mb-2"
                color="primary"
                :disabled="!formValid || isShopLoading"
                :loading="isShopLoading"
                @click="managerConfigHandler"
              >
                <v-icon>mdi-content-save</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.save")
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>

        <v-stepper-content :step="3">
          <v-card>
            <v-card-text>
              <gateway-config :config="config" :manager-config="managerConfig"/>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="mb-2"
                @click="step = 2"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.back")
                }}
              </v-btn>
              <v-btn
                class="mb-2"
                @click="step = 4"
              >
                <v-icon>mdi-chevron-right</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.next")
                }}
              </v-btn>
              <v-spacer/>
              <v-btn
                class="mb-2"
                color="error"
                :disabled="isShopLoading"
                @click="
                    $router.push({ name: 'shop_online' })
                  "
              >
                <v-icon>mdi-close</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t(
                      "$vuetify.actions.cancel"
                    )
                }}
              </v-btn>
              <v-btn
                class="mb-2"
                color="primary"
                :disabled="!formValid || isShopLoading"
                :loading="isShopLoading"
                @click="managerConfigHandler"
              >
                <v-icon>mdi-content-save</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.save")
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
        <v-stepper-content :step="4">
          <v-card>
            <v-card-text>
                <manager-inventory  :config="config" @saveArticlesShops="saveArticlesShops"/>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="mb-2"
                @click="step = 2"
              >
                <v-icon>mdi-chevron-left</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.back")
                }}
              </v-btn>
              <v-btn
                class="mb-2"
                @click="step = 1"
              >
                <v-icon>mdi-chevron-right</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.next")
                }}
              </v-btn>
              <v-spacer/>
              <v-btn
                class="mb-2"
                color="error"
                :disabled="isShopLoading"
                @click="
                    $router.push({ name: 'shop_online' })
                  "
              >
                <v-icon>mdi-close</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t(
                      "$vuetify.actions.cancel"
                    )
                }}
              </v-btn>
              <v-btn
                class="mb-2"
                color="primary"
                :disabled="!formValid || isShopLoading"
                :loading="isShopLoading"
                @click="managerConfigHandler"
              >
                <v-icon>mdi-content-save</v-icon>
                {{
                  smallScreen ? '' :
                    $vuetify.lang.t("$vuetify.actions.save")
                }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </v-card>
    <v-card v-else>
      <v-card-subtitle>
        {{$vuetify.lang.t('$vuetify.messages.no_shop_to_config')}}
      </v-card-subtitle>
      <v-card-actions>

        <v-spacer/>
        <v-btn
          class="mb-2"
          color="error"
          @click="$router.push({ name: 'shop_online' })"
        >
          <v-icon>mdi-close</v-icon>
          {{
            smallScreen ? '' :
              $vuetify.lang.t(
                "$vuetify.actions.cancel"
              )
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Basic from './steps/Basic'
import Representation from './steps/Representation'
import GatewayConfig from './steps/GatewayConfig'
import ManagerInventory from './steps/managerInventory'
export default {
  name: 'OnlineShopConfig',
  components: { ManagerInventory, GatewayConfig, Representation, Basic },
  props: {
    managerConfig: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      formValid: false,
      step: 1,
      filterData: null,
      filterSelect: [{
        text: this.$vuetify.lang.t('$vuetify.all'),
        value: 'all'
      }, {
        text: this.$vuetify.lang.t('$vuetify.online.online'),
        value: 'online'
      }, {
        text: this.$vuetify.lang.t('$vuetify.shop_article.person_sale'),
        value: 'person'
      }],
      formRule: this.$rules,
      templates: [
        {
          name: this.$vuetify.lang.t('$vuetify.online.templates.template_1'),
          value: 'template_1'
        },
        {
          name: this.$vuetify.lang.t('$vuetify.online.templates.template_2'),
          value: 'template_2'
        }
        // {
        //   name: this.$vuetify.lang.t('$vuetify.online.templates.template_3'),
        //   value: 'template_3'
        // }
      ],
      config: {
        template: 'template_1',
        name: '',
        permit_invite: true,
        shop: {},
        logo: '/assets/avatar/avatar-undefined.jpg',
        media: [],
        paypal_merchant_id: '',
        paypal_email: '',
        enable_email: true,
        enable_defaultCurrency: true,
        enable_phone: true,
        enable_address: true,
        pay_currency: 'USD',
        exchange_rate: '0.00',
        delivery: {
          price: 0.00,
          description: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters('statics', ['smallScreen']),
    ...mapGetters('auth', ['user']),
    ...mapState('shop', ['shopsNoConfig', 'isShopLoading']),
    getAvatar () {
      return this.config.logo ? this.config.logo.id ? this.config.logo.original_url : this.config.logo : '/assets/avatar/avatar-undefined.jpg'
    }
  },
  async created () {
    this.loading = true
    if (!this.managerConfig) {
      await this.getShopsNoConfig()
      this.config.shop = this.shopsNoConfig[0]
      this.config.template = this.templates[0]
      this.templateView = [
        {
          src: '/assets/shop-template/armando/assets/1.png'
        },
        {
          src: '/assets/shop-template/armando/assets/2.png'
        }
      ]
    } else {
      this.config = this.editConfig
      this.config.delivery = this.isJson(this.config.delivery) ? JSON.parse(this.config.delivery) : this.config.delivery
      this.config.media.map((imgL) => {
        imgL.path = imgL.original_url
      })
    }
    this.filterData = this.filterSelect[0]
    this.loading = false
  },
  methods: {
    ...mapActions('shop', ['getShopsNoConfig']),
    ...mapActions('online', ['createConfig', 'updateConfig']),
    isJson (str) {
      try {
        JSON.parse(str)
      } catch (e) {
        return false
      }
      return true
    },
    saveArticlesShops () {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.menu.manager_article'),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_safe_articles_online'
          ),
          icon: 'info',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.save'
          ),
          confirmButtonColor: 'primary'
        })
        .then(result => {
          if (result.value) this.updateArticlesShopOnline(this.onlineArticles)
        })
    },
    uploadImage (formData, index, fileList) {
      this.config.media = fileList
    },
    updateImageDeleted (images) {
      if (this.config.id) {
        this.config.media.forEach((v) => {
          if (v.id) {
            if (images.filter(im => im.id === v.id).length === 0) {
              this.config.remove_image.push(v.id)
            }
          }
        })
      }
    },
    onChangeImage (file) {
      if (this.config.logo) {
        if (this.config.logo.id) {
          this.config.logo.original_url = 'data:' + file.type + ';base64,' + file.base64
        } else {
          this.config.logo = 'data:' + file.type + ';base64,' + file.base64
        }
        return
      }
      this.config.logo = 'data:' + file.type + ';base64,' + file.base64
    },
    async managerConfigHandler () {
      if (this.validateConfig()) {
        if (this.managerConfig) {
          await this.updateConfig(this.config).then(data => {
            this.routeGo(data)
          })
        } else {
          await this.createConfig(this.config).then(data => {
            this.routeGo(data)
          })
        }
      }
    },
    routeGo (data) {
      if (data[0]) {
        this.$router.push({ name: 'shop_online' })
      } else {
        // this.focusElement()
        this.step = 1
      }
    },
    validateConfig () {
      if (this.config.name === '') {
        this.$Swal.fire({
          title: this.$vuetify.lang.t('$vuetify.no_defined'),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.error_name'
          ),
          icon: 'warning',
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.accept'
          ),
          confirmButtonColor: 'red'
        }).then(() => {
          this.step = 1
          // this.focusElement()
        })
        return false
      }
      return true
    },
    async loadArticleShopOnline () {
    }
  }
}
</script>

<style scoped>

</style>
