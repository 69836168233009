<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      align-self="center"
    >
      <v-card>
        <v-card-subtitle>{{ $vuetify.lang.t('$vuetify.component.logo') }}</v-card-subtitle>
        <v-card-text align="center">
          <avatar-picker
            :image-src="getAvatar"
            :image-style="{'border-radius': '50%', height: '10em', width: '10em'}"
            class="profile mx-auto d-block"
            @input="onChangeImage($event)"
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
      align-self="center"
    >
      <v-card>
        <v-card-subtitle>{{ $vuetify.lang.t('$vuetify.menu.home') }}</v-card-subtitle>
        <v-card-text align="center">
          <app-upload-multiple-image
            style="width: 100%"
            :data-images="config.media"
            :upload-success="uploadImage"
            @delete="updateImageDeleted"
          />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Representation',
  props: {
    shopsNoConfig: {
      type: Array,
      default: function () {
        return []
      }
    },
    managerConfig: {
      type: Boolean,
      default: true
    },
    isShopLoading: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    getAvatar () {
      return this.config.logo ? this.config.logo.id ? this.config.logo.original_url : this.config.logo : '/assets/avatar/avatar-undefined.jpg'
    }
  },
  methods: {
    updateImageDeleted (images) {
      if (this.config.id) {
        this.config.media.forEach((v) => {
          if (v.id) {
            if (images.filter(im => im.id === v.id).length === 0) {
              this.config.remove_image.push(v.id)
            }
          }
        })
      }
    },
    uploadImage (formData, index, fileList) {
      this.config.media = fileList
    },
    onChangeImage (file) {
      if (this.config.logo) {
        if (this.config.logo.id) {
          this.config.logo.original_url = 'data:' + file.type + ';base64,' + file.base64
        } else {
          this.config.logo = 'data:' + file.type + ';base64,' + file.base64
        }
        return
      }
      this.config.logo = 'data:' + file.type + ';base64,' + file.base64
    }
  }
}
</script>

<style scoped>

</style>
