<template>
  <v-data-table
    :items="item.variant_values.filter(art => art.shop_id === item.shop_id)"
    :headers="getTableColumns">
    <template v-slot:[`item.online_price`]="{ item }">
      <template v-if="item.variant_values.length === 0">
        <v-edit-dialog
          v-if="$route.name !== 'product_list' || ($route.name === 'product_list' && access_permit.article.actions.edit)"
          :return-value.sync="item.online_price"
          large
          persistent
          :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
          :save-text="$vuetify.lang.t('$vuetify.actions.save')"
          @save="updateArticleShop('online_price', item)"
        >
          <div v-if="parseFloat(item.online_price).toFixed(2) === '0.00'" style="color: #ef700e">
            {{
              `${parseFloat(item.online_price).toFixed(2) + " " + getCurrency}`
            }}
          </div>
          <div v-else>
            {{
              `${parseFloat(item.online_price).toFixed(2) + " " + getCurrency}`
            }}
          </div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $vuetify.lang.t("$vuetify.actions.edit") }}
            </div>
            <v-text-field-money
              v-model="item.online_price"
              :label="$vuetify.lang.t('$vuetify.actions.edit')"
              required
              :properties="{
                suffix: getCurrency,
                clearable: true
              }"
              :options="{
                length: 15,
                precision: 2,
                empty: 0.0
              }"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-else>
        ---
      </template>
    </template>
    <template v-slot:item.article.name="{ item }">
      <v-chip
        v-bind="attrs"
        v-on="on"
        pill
        style="padding-left: 0px; margin: 1px"
      >
        <v-avatar
          style="margin-left: 0px"
          :color="(item.article.color && item.article.images.length === 0 )? item.color : ''"
        >
          <v-img
            v-if="item.article.images.length > 0"
            :src="item.article.images[0].original_url"
          />
        </v-avatar>
      </v-chip>

      <v-edit-dialog
        :return-value.sync="item.article.name"
        large
        persistent
        :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
        :save-text="$vuetify.lang.t('$vuetify.actions.save')"
      >
        <div>
          <v-tooltip v-if="item.description" bottom>
            <template v-slot:activator="{ on, attrs }">
              <p v-bind="attrs" v-on="on"> {{ item.article.name }} </p>
            </template>
            <p>{{ item.article.description }}</p>
          </v-tooltip>
          <p v-else>{{ item.article.name }}</p>
        </div>
        <template v-slot:input>
          <div class="mt-4 title">
            {{ $vuetify.lang.t("$vuetify.actions.edit") }}
          </div>
          <v-text-field
            v-model="item.article.name"
            :label="$vuetify.lang.t('$vuetify.firstName')"
            required
            :rules="formRule.required"
          />
        </template>
      </v-edit-dialog>
    </template>
    <template v-slot:item.article.inventory="{ item }">
      {{ parseFloat(item.article.inventory).toFixed(2) }} {{ item.article.um ? item.article.um.name : '' }}
    </template>
    <template v-slot:[`item.online_sale`]="{ item }">
      <v-simple-checkbox v-model="item.online_sale"/>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    item: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  name: 'VariantInventory',
  data () {
    return {
      formRule: this.$rules
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'article.name',
          width: '10%',
          select_filter: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.articles.inventory'),
          value: 'article.inventory',
          select_filter: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.onlinePrice'),
          value: 'online_price',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.articles.onlineSale'),
          value: 'online_sale'
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
