<template>
  <v-card-text>
    <v-form
      ref="form"
      v-model="formValid"
      style="padding: 0"
      lazy-validation
    >
      <v-row>
        <v-col
          class="py-0"
          cols="12"
          md="4"
        >
          <v-select
            v-model="config.shop"
            chips
            rounded
            :disabled="managerConfig"
            :items="shopsNoConfig"
            :label="
                          $vuetify.lang.t(
                            '$vuetify.menu.shop'
                          )
                        "
            item-text="name"
            :loading="isShopLoading"
            return-object
            required
            :rules="formRule.country"
          />
        </v-col>
        <v-col md="8" cols="12">
          <v-text-field
            ref="name"
            v-model="config.name"
            @keypress="letters"
            @keydown="keydown"
            :hint="'Ej: https://billingmoneyapp.shop/my_online_shop_name' + '. ' +$vuetify.lang.t('$vuetify.rule.shop_name')"
            :label="$vuetify.lang.t('$vuetify.firstName')"
            :rules="formRule.required"
            prefix="https://billingmoneyapp.shop/"
            required
          />
        </v-col>
        <v-col md="4" cols="12">
          <v-switch
            v-model="config.permit_invite"
            :title="$vuetify.lang.t('$vuetify.online.permit_invite')"
          >
            <template v-slot:label>
              <div>
                {{ $vuetify.lang.t('$vuetify.online.permit_invite') }}
                <v-tooltip
                  max-width="150"
                  right
                  class="md-6"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-information-outline
                    </v-icon>
                  </template>
                  <span>{{
                      $vuetify.lang.t('$vuetify.hints.permit_invite')
                    }}</span>
                </v-tooltip>
              </div>
            </template>
          </v-switch>
        </v-col>
        <v-col
          class="py-0"
          cols="12"
          md="4"
        >
          <v-select
            v-model="config.template"
            rounded
            :items="templates"
            :label="$vuetify.lang.t('$vuetify.online.template')"
            item-text="name"
            item-value="value"
            :loading="isShopLoading"
            required
            :rules="formRule.country"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="(n, i) in templateView"
          :key="i"
          class="d-flex child-flex"
          cols="6"
        >
          <v-img
            :src="n.src"
            :lazy-src="n.src"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                />
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-form>
  </v-card-text>
</template>

<script>
export default {
  name: 'Basic',
  props: {
    shopsNoConfig: {
      type: Array,
      default: function () {
        return []
      }
    },
    managerConfig: {
      type: Boolean,
      default: true
    },
    isShopLoading: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  data () {
    return {
      formRule: this.$rules,
      formValid: false,
      templateView: [],
      templates: [
        {
          name: this.$vuetify.lang.t('$vuetify.online.templates.template_1'),
          value: 'template_1'
        },
        {
          name: this.$vuetify.lang.t('$vuetify.online.templates.template_2'),
          value: 'template_2'
        }
        // {
        //   name: this.$vuetify.lang.t('$vuetify.online.templates.template_3'),
        //   value: 'template_3'
        // }
      ]
    }
  },
  methods: {
    letters () {
      const regex = new RegExp('^[a-zA-Z0-9_]+$')
      const key = String.fromCharCode(
        !event.charCode ? event.which : event.charCode
      )
      if (!regex.test(key) || this.config.name.length > 20) {
        event.preventDefault()
        return false
      }
    },
    keydown: function (e) {
      if (e.ctrlKey) {
        event.preventDefault()
        return false
      }
    },
    focusElement () {
      this.$refs.name.focus()
    }
  }
}
</script>

<style scoped>

</style>
