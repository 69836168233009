<template>

  <v-row>
    <v-col cols="12" md="12">
      <v-card class="v-app-ad v-sheet v-sheet--outlined theme--light rounded" width="100%"
              style="margin-top: 0px">
        <v-card-subtitle>
          <h4>{{ $vuetify.lang.t('$vuetify.actions.send') }}</h4>
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field-money
                class="text-right"
                v-model="config.delivery.price"
                :label="$vuetify.lang.t('$vuetify.online.delivery')"
                required
                :properties="{
                          suffix: getCurrency
                        }"
                :options="{
                          length: 15,
                          precision: 2,
                          empty: 0.00,
                        }"
              />
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea v-model="config.delivery.description"
                          :label="$vuetify.lang.t('$vuetify.description')"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="12">
      <v-card class="v-app-ad v-sheet v-sheet--outlined theme--light rounded" width="100%"
              style="margin-top: 0px; padding: 15px">
        <v-card-subtitle>
          <h4>{{ $vuetify.lang.t('$vuetify.actions.show') }}</h4>
        </v-card-subtitle>
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="config.enable_email"
              :title="
                          $vuetify.lang.t('$vuetify.online.enable_email')
                        "
            >
              <template v-slot:label>
                <div>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.online.enable_email"
                    )
                  }}
                  <v-tooltip
                    max-width="350"
                    right
                    class="md-6"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <p>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.messages.info_enable_email"
                        )
                      }}
                    </p>
                  </v-tooltip>
                </div>
              </template>
            </v-switch>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="config.enable_phone"
              :title="
                          $vuetify.lang.t('$vuetify.online.enable_phone')
                        "
            >
              <template v-slot:label>
                <div>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.online.enable_phone"
                    )
                  }}
                  <v-tooltip
                    max-width="350"
                    right
                    class="md-6"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <p>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.messages.info_enable_phone"
                        )
                      }}
                    </p>
                  </v-tooltip>
                </div>
              </template>
            </v-switch>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="config.enable_email"
              :title="$vuetify.lang.t('$vuetify.online.enable_address')"
            >
              <template v-slot:label>
                <div>
                  {{
                    $vuetify.lang.t(
                      "$vuetify.online.enable_address"
                    )
                  }}
                  <v-tooltip
                    max-width="350"
                    right
                    class="md-6"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <p>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.messages.info_enable_address"
                        )
                      }}
                    </p>
                  </v-tooltip>
                </div>
              </template>
            </v-switch>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="config.enable_defaultCurrency"
              :title="
                          $vuetify.lang.t('$vuetify.online.enable_defaultCurrency')
                        "
            >
              <template v-slot:label>
                <div>
                  {{ $vuetify.lang.t("$vuetify.online.enable_defaultCurrency") }}
                  <v-tooltip
                    max-width="350"
                    right
                    class="md-6"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        dark
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-information-outline
                      </v-icon>
                    </template>
                    <p>
                      {{
                        $vuetify.lang.t(
                          "$vuetify.messages.info_enable_defaultCurrency"
                        )
                      }}
                    </p>
                  </v-tooltip>
                </div>
              </template>
            </v-switch>
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12" md="12">
      <v-card class="v-app-ad v-sheet v-sheet--outlined theme--light rounded" width="100%"
              style="margin-top: 0px; padding: 15px">
        <v-card-subtitle>
          <h3>{{ $vuetify.lang.t('$vuetify.plan.platform.paypal') }}</h3>
        </v-card-subtitle>
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="config.paypal_email"
              :label="$vuetify.lang.t('$vuetify.online.paypal_email')"
              autocomplete="off"
              type="email"
            />
          </v-col>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="config.paypal_merchant_id"
              :label="$vuetify.lang.t('$vuetify.online.merchant_id')"
              autocomplete="off"
              name="register"
              :hint="$vuetify.lang.t('$vuetify.messages.merchant_id_paypal')"
              persistent-hint
              type="text"
            />
          </v-col>
          <v-col md="6" cols="12" v-if="config.paypal_merchant_id">
            <v-select
              v-model="config.pay_currency"
              item-text="name"
              item-value="value"
              :items="currencies"
              :label="$vuetify.lang.t('$vuetify.currency')"
              required
              :rules="formRule.required"
            />
          </v-col>
          <v-col md="6" cols="12" v-if="config.paypal_merchant_id">
            <v-text-field-money
              class="text-right"
              v-model="config.exchange_rate"
              :label="$vuetify.lang.t('$vuetify.change')"
              required
              :properties="{
                              prefix: '1,00 ' + config.pay_currency + ' x' ,
                              suffix: getCurrency
                            }"
              :options="{
                              length: 15,
                              precision: 2,
                              empty: 0.00,
                            }"
            />
          </v-col>
          <v-col md="12" cols="12" v-if="config.paypal_merchant_id && parseFloat(config.exchange_rate).toFixed(2) !== '0.00'">
            <p>{{
                $vuetify.lang.t('$vuetify.messages.paypal_currency_example_exclange',
                  getCurrency, config.pay_currency, config.exchange_rate, parseFloat(100 / config.exchange_rate).toFixed(2))
              }}</p>
          </v-col>
          <v-col md="12" cols="12" v-if="config.paypal_merchant_id"><p>{{ $vuetify.lang.t('$vuetify.messages.paypal_currency_note') }}</p></v-col>
          <v-col md="12" cols="12" v-if="config.paypal_merchant_id"><p>{{ $vuetify.lang.t('$vuetify.messages.paypal_currency_note_1') }}</p>
          </v-col>
          <v-col md="12" cols="12" v-if="config.paypal_merchant_id">
            <p>{{ $vuetify.lang.t('$vuetify.messages.paypal_currency_note_2') }}</p>
          </v-col>
          <v-col md="12" cols="12" v-if="config.paypal_merchant_id">{{ $vuetify.lang.t('$vuetify.messages.paypal_currency_note_3') }}
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GatewayConfig',
  props: {
    managerConfig: {
      type: Boolean,
      default: true
    },
    isShopLoading: {
      type: Boolean,
      default: true
    },
    config: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '$' : '$'
    }
  }
}
</script>

<style scoped>

</style>
