<template>
  <v-container>
    <v-dialog v-model="showImageRepresentation" max-width="450" persistent v-if="articleSelected">
      <multi-image-representation :images="articleSelected.images" :path-image="'original_url'" @saveImages="saveImages"
                                  @cancel="cancelChangeImage"/>
    </v-dialog>
    <app-loading v-if="loading"/>
    <v-data-table v-else
                  :headers="getTableColumns"
                  :items="localArticles"
                  :options="vBindOption"
                  show-expand
                  class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-spacer/>
          <v-btn @click="$emit('saveArticlesShops')" color="primary">
            {{ $vuetify.lang.t('$vuetify.actions.save') }}
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.article.name="{ item }">
        <v-edit-dialog v-if="item.variant_values.length === 0"
          :return-value.sync="item.article.name"
          large
          persistent
          :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
          :save-text="$vuetify.lang.t('$vuetify.actions.save')"
        >
          <div>
            <v-tooltip v-if="item.description" bottom>
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on"> {{ item.article.name }} </p>
              </template>
              <p>{{ item.article.description }}</p>
            </v-tooltip>
            <p v-else>{{ item.article.name }}</p>
          </div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $vuetify.lang.t("$vuetify.actions.edit") }}
            </div>
            <v-text-field
              v-model="item.article.name"
              :label="$vuetify.lang.t('$vuetify.firstName')"
              required
              :rules="formRule.required"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.online_price`]="{ item }">
        <template v-if="item.variant_values.length === 0">
          <v-edit-dialog
            v-if="$route.name !== 'product_list' || ($route.name === 'product_list' && access_permit.article.actions.edit)"
            :return-value.sync="item.online_price"
            large
            persistent
            :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
            :save-text="$vuetify.lang.t('$vuetify.actions.save')"
            @save="updateArticleShop('online_price', item)"
          >
            <div v-if="parseFloat(item.online_price).toFixed(2) === '0.00'" style="color: #ef700e">
              {{
                `${parseFloat(item.online_price).toFixed(2) + " " + getCurrency}`
              }}
            </div>
            <div v-else>
              {{
                `${parseFloat(item.online_price).toFixed(2) + " " + getCurrency}`
              }}
            </div>
            <template v-slot:input>
              <div class="mt-4 title">
                {{ $vuetify.lang.t("$vuetify.actions.edit") }}
              </div>
              <v-text-field-money
                v-model="item.online_price"
                :label="$vuetify.lang.t('$vuetify.actions.edit')"
                required
                :properties="{
                suffix: getCurrency,
                clearable: true
              }"
                :options="{
                length: 15,
                precision: 2,
                empty: 0.0
              }"
              />
            </template>
          </v-edit-dialog>
        </template>
        <template v-else>
          ---
        </template>
      </template>
      <template v-slot:[`item.article.ref`]="{ item }">
        <v-icon v-if="!item.online_sale" color="red" small>
          mdi-close-circle
        </v-icon>
        {{ item.article.ref }}
      </template>
      <template v-slot:[`item.online_sale`]="{ item }">
        <v-simple-checkbox v-model="item.online_sale"/>
      </template>
      <template v-slot:[`item.onlinePrice`]="{ item }">
        <v-edit-dialog
          :return-value.sync="item.onlinePrice"
          large
          persistent
          :cancel-text="$vuetify.lang.t('$vuetify.actions.cancel')"
          :save-text="$vuetify.lang.t('$vuetify.actions.save')"
        >
          <div :style="parseFloat(item.onlinePrice).toFixed(2) === '0.00' ?'color: red': ''">
            <p v-if="config.paypal_merchant_id">
              {{ parseFloat(item.onlinePrice / config.exchange_rate).toFixed(2) + ' ' + config.pay_currency }}
              <b v-if="config.enable_defaultCurrency">({{ `${item.onlinePrice + " " + getCurrency}` }})</b>
            </p>
            <p v-else>{{ `${item.onlinePrice + " " + getCurrency}` }}</p>
            <v-tooltip
              max-width="150"
              bottom
              class="md-6"
              v-if="parseFloat(item.onlinePrice).toFixed(2) === '0.00'"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  color="red"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{
                  $vuetify.lang.t('$vuetify.hints.online_price_zero')
                }}</span>
            </v-tooltip>
          </div>
          <template v-slot:input>
            <div class="mt-4 title">
              {{ $vuetify.lang.t("$vuetify.actions.edit") }}
            </div>
            <v-text-field-money
              v-model="item.onlinePrice"
              :label="$vuetify.lang.t('$vuetify.actions.edit')"
              required
              :properties="{
                            prefix: user.company.currency,
                            clearable: true
                          }"
              :options="{
                            length: 15,
                            precision: 2,
                            empty: 0.0
                          }"
            />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.article.inventory="{ item }">
        {{ parseFloat(item.article.inventory).toFixed(2) }} {{ item.article.um ? item.article.um.name : '' }}
      </template>
      <template v-slot:[`item.data-table-expand`]="{ item, expand, isExpanded }">

        <v-btn
          v-if="item.article.variant_values.length > 0"
          color="primary"
          fab
          x-small
          dark
          @click="expand(!isExpanded)"
        >
          <v-icon v-if="isExpanded">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </v-btn>
        <v-btn
          v-else
          fab
          x-small
          disabled
        >
          <v-icon>
            mdi-check
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          :colspan="headers.length"
          style="padding: 0 0 0 0"
        >
          <variant-inventory :item="item"/>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import MultiImageRepresentation from './multi-image-representation'
import Variants from '../../article/commerce/manager-article/Variants'
import ArticleShop from '../../article/commerce/manager-article/ArticleShop'
import VariantInventory from './VariantInventory'

export default {
  name: 'managerInventory',
  components: { VariantInventory, MultiImageRepresentation },
  props: {
    config: {
      type: Object,
      default: function () {
        return null
      }
    }
  },
  data () {
    return {
      formRule: this.$rules,
      articleSelected: null,
      showImageRepresentation: false,
      vBindOption: {
        showExpand: true
      },
      loading: false,
      localArticles: []
    }
  },
  computed: {
    ...mapState('articleShop', ['onlineArticles']),
    ...mapGetters('auth', ['user']),
    getCurrency () {
      return this.user ? this.user.company ? this.user.company.currency : '' : ''
    },
    getTableColumns () {
      return [
        {
          text: this.$vuetify.lang.t('$vuetify.firstName'),
          value: 'article.name',
          width: '10%',
          select_filter: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.articles.inventory'),
          value: 'article.inventory',
          select_filter: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.onlinePrice'),
          value: 'online_price',
          select_filter: true
        },
        {
          text: this.$vuetify.lang.t('$vuetify.articles.onlineSale'),
          value: 'online_sale'
        }
      ]
    }
  },
  watch: {
    'config.shop': function () {
      this.loadArticles()
    }
  },
  created () {
    this.loadArticles()
  },
  methods: {
    ...mapActions('articleShop', ['getOnlineArticleSale', 'updateArticlesShopOnline']),
    articleInShop (item, variants) {
      const variant = []
      if (variants) {
        item.article.variant_values.forEach((vv) => {
          vv.article_shops.forEach((artS) => {
            if (artS.shop_id === this.config.shop.id) {
              variant.push(vv)
            }
          })
        })
      } else {
        item.article_shops.forEach((artS) => {
          if (artS.shop_id === this.config.shop.id) {
            variant.push(artS)
          }
        })
      }
      return variant
    },
    async loadArticles () {
      this.loading = true
      await this.getOnlineArticleSale({ shop: this.config.shop })
      this.localArticles = this.onlineArticles
      this.loading = false
    }
  }
}
</script>

<style scoped>

</style>
