<template>
  <v-card>
    <v-card-subtitle>{{ $vuetify.lang.t("$vuetify.representation.representation") }}</v-card-subtitle>
    <v-card-text>
      <v-file-input v-model="image" outlined dense @change="onFileChange" />
      <v-row>
        <v-col cols="12" md="4" v-for="(localImg, i) in localImage" :key="i" style="border-width: 15px solid black">
          <v-card max-width="64" max-height="100">
            <v-img :src="localImg.original_url" style="width: 64px; height: 64px"/>
            <v-card-actions>
              <v-btn icon class="mx-2" color="red" @click="deleteImage(localImg)"><v-icon>mdi-delete-circle</v-icon></v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="error" @click="$emit('cancel')">{{$vuetify.lang.t('$vuetify.actions.cancel')}}</v-btn>
      <v-spacer/>
      <v-btn color="primary" @click="saveImages">{{$vuetify.lang.t('$vuetify.actions.save')}}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'multi-image-representation',
  props: {
    pathImage: {
      type: String,
      default: 'path'
    },
    images: {
      type: Array,
      default: function () {
        return []
      }
    },
    imagesDeleted: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data () {
    return {
      localImage: [...this.images],
      localDeleted: [...this.imagesDeleted],
      image: null,
      imageUrl: ''
    }
  },
  methods: {
    saveImages () {
      this.$emit('saveImages', this.localImage, this.localDeleted)
    },
    deleteImage (img) {
      this.$Swal
        .fire({
          title: this.$vuetify.lang.t('$vuetify.titles.delete', [
            this.$vuetify.lang.t('$vuetify.component.image')
          ]),
          text: this.$vuetify.lang.t(
            '$vuetify.messages.warning_delete'
          ),
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.cancel'
          ),
          confirmButtonText: this.$vuetify.lang.t(
            '$vuetify.actions.delete'
          ),
          confirmButtonColor: 'red'
        })
        .then(result => {
          if (result.isConfirmed) {
            if (img.id) { this.localDeleted.push(img.id) }
            const localRemove = this.localImage.filter((lImg) => lImg !== img)
            this.localImage = localRemove
          }
        })
    },
    createImage (file) {
      const reader = new FileReader()
      reader.onload = e => {
        this.localImage.push({
          original_url: e.target.result
        })
      }
      reader.readAsDataURL(file)
    },
    onFileChange (file) {
      if (!file) {
        return
      }
      this.createImage(file)
    }
  }
}
</script>

<style scoped>

</style>
